import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import { Auth } from "aws-amplify";
import { AlertTitle, Chip, CircularProgress, DialogContent, DialogTitle, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CancelIcon from '@mui/icons-material/Cancel';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import ErrorIcon from '@mui/icons-material/Error';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const StudentReport = ( {handleClose, testId, studId} ) => {
  // const [open, setOpen] = useState(false);
  // const maxWidth = "lg"
  const [tabValue, setTabValue] = useState(0);
  const [reportJson, setReportJson] = useState("");
  const [error, setError] = useState("")
  const audioRef = useRef([])

  useEffect(() => {
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        const facId = data.idToken.payload["custom:db_id"]

        const getReportData = async(facId, testId, studId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/student-report?testId="+testId+"&studId="+studId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log(response.data)
            // console.log("Student Report Data: ", {...response.data, "reportCard": response.data.reportCard.sort((a, b) => a.lang > b.lang ? 1 : -1)})
            setReportJson({...response.data, "reportCard": response.data.reportCard.sort((a, b) => a.lang > b.lang ? 1 : -1)})
          }
          catch (error) {
            if(error.response.status === 404) setError({"status": "warning", "msg": "Report does not exist!"})
            else{
              setError({"status": "error", "msg": "Internal Server Error - Please contact IITB Team"})
              console.log(error)
            }
          }
        }

        getReportData(facId, testId, studId)
      })
      .catch((err) => console.log(err));
  }, [testId, studId]);

  // const handleClickOpen = () => {
  //   if(reportJson === ""){
  //     Auth.currentSession()
  //     .then((data) => {
  //       const jwtToken = data.idToken.jwtToken;
  //       const facId = data.idToken.payload["custom:db_id"]

  //       const getReportData = async(facId, testId, studId) => {
  //         try {
  //           const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/student-report?testId="+testId+"&studId="+studId, {
  //               headers: {
  //                   'Content-Type': 'application/json',
  //                   'Authorization': jwtToken
  //               }
  //           });
  //           // console.log(response.data)
  //           // console.log("Student Report Data: ", {...response.data, "reportCard": response.data.reportCard.sort((a, b) => a.lang > b.lang ? 1 : -1)})
  //           setReportJson({...response.data, "reportCard": response.data.reportCard.sort((a, b) => a.lang > b.lang ? 1 : -1)})
  //         }
  //         catch (error) {
  //           if(error.response.status === 404) setError({"status": "warning", "msg": "Report does not exist!"})
  //           else{
  //             setError({"status": "error", "msg": "Internal Server Error - Please contact IITB Team"})
  //             console.log(error)
  //           }
  //         }
  //       }

  //       getReportData(facId, testId, studId)
  //     })
  //     .catch((err) => console.log(err));
  //   }

  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   // console.log(audioRef)
  //   audioRef.current.map(audio => audio.pause())
  // };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const pauseAudio = (e) => {
    // console.log(e)
    audioRef.current.filter(i => i !== e.target).map((audio => audio.pause()))
  }

  return (
    <>
      {/* <Button 
        size="small"
        onClick={handleClickOpen}
        variant="contained" startIcon={<AssessmentIcon />} 
      >
        View Report
      </Button>   */}
      {/* <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth={maxWidth}
        scroll="paper"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            backgroundColor: 'var(--new-bg-color)',
            borderRadius: '10px'
          }
        }}
      > */}
        <DialogTitle sx={{display: "flex", alignItems: "center", gap: "0.5rem", flexWrap: "wrap"}}>
          <AssessmentIcon color="info" sx={{fontSize: "1.25rem"}}/>
          <b>Student Report</b>
          {reportJson && 
            <p style={{fontWeight: "bold", fontSize: "0.67em", marginLeft: "auto", marginRight: "20px"}}>
              {reportJson.studentDetails.schoolName + " Shift " + reportJson.studentDetails.shift + ", " + reportJson.studentDetails.region}
            </p>
          }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className='studentReport'>
            {/* <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Student Report</h2> */}
            {reportJson ?
            <>
              <div className="countTileContainer" style={{padding: "unset"}}>
                <div className="countTile" style={{gap: "0.5rem"}}>
                  <div className="countTileLeft">Name:</div>
                  <div className="countTileRight">{reportJson.studentDetails.firstName + " " + reportJson.studentDetails.lastName}</div>
                </div>
                <div className="countTile" style={{gap: "0.5rem"}}>
                  <div className="countTileLeft">PEN No:</div>
                  <div className="countTileRight">{reportJson.studentDetails.id}</div>
                </div>
                <div className="countTile" style={{gap: "0.5rem"}}>
                  <div className="countTileLeft">Class:</div>
                  <div className="countTileRight">{reportJson.studentDetails.grade}</div>
                </div>
                <div className="countTile" style={{gap: "0.5rem"}}>
                  <div className="countTileLeft">Division:</div>
                  <div className="countTileRight">{reportJson.studentDetails.div}</div>
                </div>
                <div className="countTile" style={{gap: "0.5rem"}}>
                  <div className="countTileLeft">Roll No:</div>
                  <div className="countTileRight">{reportJson.studentDetails.rollNo}</div>
                </div>
                <div className="countTile" style={{gap: "0.5rem"}}>
                  <div className="countTileLeft">Gender:</div>
                  <div className="countTileRight">{reportJson.studentDetails.gender}</div>
                </div>
              </div>
              <div>
                <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px"}}>
                  {reportJson.reportCard.map((item, i) => {
                    return <Tab 
                              key = {i}
                              label={item.lang === "EN" ? "English" : item.lang === "HI" ? "Hindi" : item.lang} 
                              sx={{fontSize: "inherit", 
                              minHeight: "30px"}}
                            />
                  })}
                </Tabs>
              </div>
              <div className="reportContainer">
                <div className="reportContainerLeft">
                  <div className="paraContainer paraContainerTop">
                    <div style={{flexBasis: "100%"}}><h4>Story Read: {reportJson.reportCard[tabValue].storyTitle}</h4></div>
                    {/* <h4>Story Title: {reportJson.reportCard[tabValue].stotyTitle}</h4> */}
                    {/* <p style={{fontSize: "14px", backgroundColor: "#0288d1", color: "#fff", borderRadius: "10px", padding: "12px"}}>Attempt Summary: <b style={{color: "green"}}>Valid Attempt</b></p> */}
                    <p>WCPM: <b>{reportJson.reportCard[tabValue].reportCard.wcpm >= 0 ? reportJson.reportCard[tabValue].reportCard.wcpm : "NA"}</b></p>
                    <p>Reading Accuracy: <b>{reportJson.reportCard[tabValue].reportCard.readingAccuracy + "%"}</b></p>
                    <p>Pace: <b>{reportJson.reportCard[tabValue].reportCard.sr !== -1 ? reportJson.reportCard[tabValue].reportCard.sr + " syl/sec": "NA"}</b></p>
                    <p>Word Mistakes: <b>{reportJson.reportCard[tabValue].reportCard.noMiscues !== -1 ? reportJson.reportCard[tabValue].reportCard.noMiscues : "NA"}</b></p>
                    <p>Practice Words: <b>{reportJson.reportCard[tabValue].reportCard.practiceWords.length ? reportJson.reportCard[tabValue].reportCard.practiceWords.toString() : "-"}</b></p>
                    <p>Phrasing Score: <b>{reportJson.reportCard[tabValue].reportCard.phrasingScore !== -1 ? reportJson.reportCard[tabValue].reportCard.phrasingScore : "NA"}</b></p>
                    <p>Improper Phrase Breaks <span style={{color: "red"}}><b>||</b></span>: <b>{reportJson.reportCard[tabValue].reportCard.phrasingMiscues.i !== -1 ? reportJson.reportCard[tabValue].reportCard.phrasingMiscues.i : "NA"}</b></p>
                    <p>Missed Phrase Breaks <span style={{color: "orange"}}><b>||</b></span>: <b>{reportJson.reportCard[tabValue].reportCard.phrasingMiscues.d !== -1 ? reportJson.reportCard[tabValue].reportCard.phrasingMiscues.d : "NA"}</b></p>
                    {/* <Chip label="WCPM: 60" color='info'></Chip>
                    <Chip label="Pace: Slow (1.8 syl/sec.)" color='info'></Chip>
                    <Chip label="Word Mistakes: 10" color='info'></Chip>
                    <Chip label="Practice Words: the,to,considered" color='info'></Chip>
                    <Chip label="Phrasing Score: NA" color='info'></Chip> */}
                  </div>
                  {reportJson.reportCard[tabValue].reportCard.paraResults.map((item, i) => {
                    return <div key = {i} className="paraContainer">
                            <div className="paraDiv">
                              <h4>Paragraph: {item.paraNo}</h4>
                              {item.s3Url !== "" && 
                                <audio
                                  ref={ref => audioRef.current[i] = ref}
                                  onPlaying={(e) => pauseAudio(e)}
                                  // onError={(e) => console.log(e)}
                                  controls
                                  // controlsList="nodownload noplaybackrate"
                                  // src={"https://www.ee.iitb.ac.in/LMEDS/audio_examples/KV/audios/27179910008431_1700296121306_Para2-Take1.m4a"}
                                  src={item.s3Url}
                                  style={{height: "2rem", borderRadius: "10px", border: "1px solid #242d49"}}
                                />
                              }
                              {item.resultStatus === 1 && <Chip icon={<CommentsDisabledIcon/>} color='error' label="Incomplete Attempt" /> }
                              {item.resultStatus === 2 && <Chip icon={<MusicOffIcon/>} color='error' label="Blank Audio" /> }
                              {item.resultStatus === 3 && <Chip icon={<ErrorIcon/>} color='error' label="Missing Audio" /> }
                            </div>
                            <p style={{marginTop: "0.5rem"}}>
                              {item.wordFeedback.map((w, i) => {
                                return <React.Fragment key={i}>
                                  {" "}
                                  {
                                    w.miscueLabel === "c" ? <span style={{color: "green"}}>{w.promptWord}</span> :
                                    w.miscueLabel === "i" ? <ins>{w.decodedWord}</ins> :
                                    w.miscueLabel === "s" ? <span style={{color: "orange"}}>{w.decodedWord}({w.promptWord})</span> :
                                    w.miscueLabel === "d" ? <del>{w.promptWord}</del> : console.log("Unidentified miscue label: ", w.miscueLabel)
                                  }
                                  {w.pbLabel && " "}
                                  {
                                    w.pbLabel === "" ? "" : 
                                    w.pbLabel === "c" ? <span style={{color: "green", fontFamily: "bold"}}>||</span> :
                                    w.pbLabel === "i" ? <span style={{color: "red", fontFamily: "bold"}}>||</span> :
                                    w.pbLabel === "d" ? <span style={{color: "orange", fontFamily: "bold"}}>||</span> : console.log("Unidentified pb label: ", w.pbLabel)
                                  }
                                </React.Fragment>
                              })}
                            </p>
                    </div>  
                  })}
                </div>
                <div className="reportContainerRight">
                  <div className="attemptContainer">
                    <h4>Attempt Summary:</h4>
                    {/* <p style={{color: "var(--success-color)"}}>Valid Attempt</p> */}
                    {reportJson.reportCard[tabValue].resultStatus ?
                      <Chip icon={<CancelIcon/>} color='error' label="Invalid Attempt" />
                      :
                      <Chip icon={<CheckCircleIcon/>} color='success' label="Valid Attempt" />
                    }
                    {reportJson.reportCard[tabValue].reportCard.micConnected ?
                      <Chip icon={<CheckCircleIcon/>} color='success' label="Earphone Connected" />
                      :
                      <Chip icon={<CancelIcon/>} color='error' label="Earphone Not Connected" />
                    }
                  </div>
                  <div className="legendContainer">
                    <TableContainer style={{width: "unset"}}>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell align='center' style={{padding: "6px 6px"}}><h4>Word Mistakes</h4></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow><TableCell style={{padding: "6px 6px"}}><span style={{color: "green"}}>Correct</span></TableCell></TableRow>
                          <TableRow><TableCell style={{padding: "6px 6px"}}><span style={{color: "orange"}}>Substition(Word)</span></TableCell></TableRow>
                          <TableRow><TableCell style={{padding: "6px 6px"}}><del>Deletion</del></TableCell></TableRow>
                          <TableRow><TableCell style={{padding: "6px 6px"}}><ins> Insertion </ins></TableCell></TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer style={{width: "unset"}}>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell align='center' style={{padding: "6px 6px"}}><h4>Phrase Breaks ||</h4></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow><TableCell style={{padding: "6px 6px"}}><span style={{color: "green"}}>|| - Correct</span></TableCell></TableRow>
                          <TableRow><TableCell style={{padding: "6px 6px"}}><span style={{color: "red"}}>|| - Improper Breaks</span></TableCell></TableRow>
                          <TableRow><TableCell style={{padding: "6px 6px"}}><span style={{color: "orange"}}>|| - Missed Breaks</span></TableCell></TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </>
            : ( error ?
                <MuiAlert severity={error.status} className="blankScreenError" sx={{borderRadius: "10px"}}>
                  <AlertTitle>{error.msg}</AlertTitle>
                </MuiAlert>
                :
                <CircularProgress color="info" style={{placeSelf: "center"}}/>) }
          </div>
        </DialogContent>
      {/* </Dialog> */}
    </>
  )
}

export default StudentReport