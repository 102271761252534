import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Routes, Route, Navigate } from "react-router-dom";
import { AlertTitle, CircularProgress, Divider, List, ListItem } from "@mui/material";
import MuiAlert from '@mui/material/Alert';

import Dashboard from "./Dashboard"
import Students from "./Students";
import Report from "./Report";
import Intervention from "./Intervention";

const FacilitatorContent = () => {
  const [facInfo, setFacInfo] = useState("")
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState([])

  useEffect(() => {
    Auth.currentSession()
    .then((data) => {
      const jwtToken = data.idToken.jwtToken;
      const cognitoUsername = data.idToken.payload["cognito:username"];

      const getFacInfo = async() => {
        try {
          const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+cognitoUsername, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': jwtToken
            }
          });
          // console.log(response.data)
          setFacInfo(response.data)
        }
        catch (error) {
          setError(true)
          setErrorMsg([error.code, error.message])
          console.log(error)
        }
      }

      getFacInfo()
    })
    .catch((err) => console.log(err));
    
  }, []);

  return (
    <div className="content">
        { facInfo
          ?
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/students" element={<Students facInfo={facInfo}/>} />
            <Route path="/results" element={<Report facInfo={facInfo}/>} />
            <Route path="/intervention" element={<Intervention facInfo={facInfo}/>} />
            <Route path="*" element={<Navigate to="/"/>} />
          </Routes>
          :
          <div className="blankScreen">
            {error 
              ?
              <MuiAlert severity="warning" className="blankScreenError" sx={{borderRadius: "10px"}}>
                <AlertTitle><h3>Connection Issue:</h3></AlertTitle>
                  <Divider/>
                  <List>
                    <ListItem disableGutters><h3>Please check your Internet Connectivity & try refreshing the page.</h3></ListItem>
                    <ListItem disableGutters><p>If the issue persists please contact IITB Team using the following <span></span>
                      <a href="https://forms.gle/fnU48UpGWWs5zgoT6" target="_blank" rel="noopener noreferrer" style={{color: "rgb(25, 118, 210)", textDecoration: "underline", textUnderlinePosition: "under"}}> 
                        form.
                      </a></p>
                      </ListItem>
                    {errorMsg && <ListItem disableGutters><p>Response Code: {errorMsg[0]}, Response Message: {errorMsg[1]}</p></ListItem>}
                  </List>
              </MuiAlert>
              :
              <CircularProgress color="info"/>
            }
          </div>
        }
    </div>
  )
};

export default FacilitatorContent;
