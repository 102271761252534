import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator, CheckboxField, useAuthenticator } from "@aws-amplify/ui-react";
import { Button } from "@mui/material";

import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import Navbar from "./components/Navbar";
import Sidebar from "./components/views/SuperAdmin/Sidebar";
import Content from "./components/views/SuperAdmin/Content";
import AdminSidebar from "./components/views/Admin/AdminSidebar"
import AdminContent from "./components/views/Admin/AdminContent";
import FacilitatorSidebar from "./components/views/Facilitator/FacilitatorSidebar";
import FacilitatorContent from "./components/views/Facilitator/FacilitatorContent";
import PrivacyPolicy from "./components/PrivacyPolicy";

import TARAHeader from "./data/tara-header.png"
import KVSLogo from "./data/KVS-logo.png";
import TCTDLogo from "./data/TCTD-logo.png";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID,
  },
});

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
          <Route path="*" element=
            {
              <Authenticator
              formFields={{
                signIn: {
                  username: {
                    label: "Email",
                    placeholder: "Enter your Email",
                    isRequired: true
                  },
                }
              }}
              hideSignUp={true}           // to enable sign up change this to false or comment this
              // socialProviders={["google"]}
              components={{
                Header() {   
                  return (
                    <div className="amplifyHeader">
                      <img alt="TARA Header" src={TARAHeader}></img>
                    </div>
                  );
                },
                Footer() {
                  return (
                    <div className="amplifyFooter">
                      <div className="logos">
                          <img alt="TCTD logo" src={KVSLogo} />
                          <img alt="TCTD logo" src={TCTDLogo} />
                        </div>
                    </div>
                  );
                },
                SignUp: {
                  FormFields() {
                    const { validationErrors } = useAuthenticator();
        
                    return (
                      <>
                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                        <Authenticator.SignUp.FormFields />
        
                        {/* Append & require Terms & Conditions field to sign up  */}
                        <CheckboxField
                          errorMessage={validationErrors.acknowledgement}
                          hasError={!!validationErrors.acknowledgement}
                          name="acknowledgement"
                          value="yes"
                          label={
                            <>
                              <span>
                                By signing-in, you agree to our
                              </span>
                              <Button 
                                variant="text" 
                                component ={Link}
                                target="_blank"
                                to="/privacy-policy" 
                                sx={{padding: "0", paddingLeft: "0.25rem", fontWeight: "bold", textTransform: "capitalize", textDecoration: "underline", textUnderlinePosition: "under"}}
                              >
                                Terms, Privacy Policy & Cookies Policy.
                              </Button>
                            </>
                          }
                        />
                      </>
                    );
                  },
                },
                SignIn: {
                  Footer() {
                    // const { toResetPassword } = useAuthenticator();
                    
                    return (
                      <div style={{textAlign: "center", paddingBottom: "0.5rem"}}>
                        <div style={{textAlign: "center", paddingBottom: "0.5rem"}}>
                          <span>
                            Trouble signing in?
                          </span>
                          <Button 
                            variant="text" 
                            component ={Link}
                            target="_blank"
                            rel='noopener noreferrer'
                            to="https://forms.gle/fnU48UpGWWs5zgoT6" 
                            sx={{padding: "0", paddingLeft: "0.25rem", fontWeight: "bold", textTransform: "capitalize", textDecoration: "underline", textUnderlinePosition: "under"}}
                          >
                            Click Here
                          </Button>
                        </div>
                        <span>
                          By signing-in, you agree to our
                        </span>
                        <Button 
                          variant="text" 
                          component ={Link}
                          target="_blank"
                          to="/privacy-policy" 
                          sx={{padding: "0", paddingLeft: "0.25rem", fontWeight: "bold", textTransform: "capitalize", textDecoration: "underline", textUnderlinePosition: "under"}}
                        >
                          Terms, Privacy Policy & Cookies Policy.
                        </Button>
                        {/* <Button
                          onClick={toResetPassword}
                          size="small"
                          variation="link"
                        >
                          Reset Password
                        </Button> */}
                      </div>
                    );
                  },
                },
              }}
              services={{
                async validateCustomSignUp(formData) {
                  if (!formData.acknowledgement) {
                    return {
                      acknowledgement: "You must agree to Terms, Privacy Policy & Cookies Policy.",
                    };
                  }
                },
              }}
              >
                {({ signOut, user }) => (
                  <>
                    {/* {console.log(user)} */}
                    <Navbar 
                      signOut={signOut} 
                      user = {user} 
                    />
                    <div className="mainContent">
                      {/* <Router> */}
                        {user.signInUserSession.idToken.payload["cognito:groups"][0] === "SuperAdmin" &&
                          <>
                            <Sidebar />
                            <Content />
                          </>
                        }
                        {user.signInUserSession.idToken.payload["cognito:groups"][0] === "AdminGroup" &&
                          <>
                            <AdminSidebar />
                            <AdminContent />
                          </>
                        }
                        {user.signInUserSession.idToken.payload["cognito:groups"][0] === "FacilitatorGroup" &&
                          <>
                            <FacilitatorSidebar />
                            <FacilitatorContent />
                          </>
                        }
                      {/* </Router> */}
                    </div>
                  </>
                )}
              </Authenticator>
            }
          />
        </Routes>
        
      </Router>
    </div>
  );
}

export default App;
