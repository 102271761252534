import React, { useState, useEffect } from 'react'
import { Chip, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import axios from "axios"
import { Auth } from "aws-amplify";
import FilterListIcon from '@mui/icons-material/FilterList';
import TranslateIcon from '@mui/icons-material/Translate';
import MapIcon from '@mui/icons-material/Map';
import PinIcon from '@mui/icons-material/Pin';
import EditNoteIcon from '@mui/icons-material/EditNote';

import BarChart from '../../BarChart';

const Report = ({ superAdminInfo }) => {
  const [reportData, setReportData] = useState("")
  const [testId, setTestId] = useState(superAdminInfo.allTests ? superAdminInfo.allTests[0].testId : null)
  
  useEffect(() => {
    if(superAdminInfo.allTests){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const getReportData = async(superAdminId, testId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/superAdmin/"+superAdminId+"/report?testId="+testId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Report Data: ",response.data["report"])
            setReportData(response.data["report"])
            setStd(response.data["report"]["allSchools"].map(item => item.std).sort()[0] || "")
            setSchoolRegion([...new Set(response.data["report"]["allSchools"].map((item) => item.region))][0] || "")
          }
          catch (error) {
              console.log(error)
          }
        }
  
        getReportData(superAdminInfo.superAdmin.superAdminId, testId)
        
      })
      .catch((err) => console.log(err));
    }
    else{
      alert("No Test has been completed yet!\nRedirecting to Dashboard")
      window.location.replace("/");
    }
    
  }, [superAdminInfo, testId]);

  const [tabValue, setTabValue] = useState(0);
  const [std, setStd] = useState("");
  const [lang, setLang] = useState("English");
  const [schoolRegion, setSchoolRegion] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTestIdChange = (e) => {
    setStd("");
    setSchoolRegion("");
    setReportData("");
    setTestId(e.target.value);
  };

  const handleStdChange = (e) => {
    setStd(e.target.value);
  };

  const handleLangChange = (e) => {
    setLang(e.target.value);
  };

  const handleSchoolRegionChange = (e) => {
    setSchoolRegion(e.target.value);
  };

  const handleSchoolRegionChipDelete = () => {
    setSchoolRegion("");
  };

  var filteredReportData = reportData
  if(std!== "") filteredReportData = {
    "allSchools": reportData["allSchools"].filter(item => item.std === std),
    "thresholds": reportData["thresholds"].filter(item => item.std === std)[0]
  }

  return (
    <div className='report superAdminReport innerContent'>
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Results</h2>
      <div className="chartArea">
        <div className="topTabs">
          <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px", width: "30vw"}}>
            <Tab label="Region Wise" sx={{fontSize: "inherit", minHeight: "30px"}}/>
            <Tab label="School Wise" sx={{fontSize: "inherit", minHeight: "30px"}}/>
          </Tabs>
          <h4 style={{color: "#dc3545"}}>
            Results generated based on recordings received till {superAdminInfo.allTests && 
              new Date(superAdminInfo.allTests.filter(item=> item.testId === testId)[0].uploadEndDate).toLocaleDateString("en-GB", { dateStyle: "long" })}
          </h4>
        </div>
        <div className="filters">
          <div className="forms">
            <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
              <FilterListIcon fontSize="small"/>
              <span style={{height: "100%"}}>Filters:</span> 
            </h4>
            <FormControl sx={{ m: 1, minWidth: 150}} size="small">
              <InputLabel id="testNameFilterLabel">Test Name</InputLabel>
              <Select
                labelId="testIdFilterLabel"
                id="testIdFilter"
                value={testId ? testId : ""}
                onChange={handleTestIdChange}
                autoWidth
                label="Test Name"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* getting all unique std values from data */}
                {superAdminInfo.allTests &&
                  superAdminInfo.allTests.map(item => item.testName).map((item, i) => {
                  return <MenuItem key={i} value={superAdminInfo.allTests[i].testId}>{item}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 150}} size="small">
              <InputLabel id="langFilterLabel">Language</InputLabel>
              <Select
                labelId="langFilterLabel"
                id="langFilter"
                value={lang}
                onChange={handleLangChange}
                autoWidth
                label="Language"
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Hindi">Hindi</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 85}} size="small">
              <InputLabel id="stdFilterLabel">Class</InputLabel>
              <Select
                labelId="stdFilterLabel"
                id="stdFilter"
                value={std}
                onChange={handleStdChange}
                autoWidth
                label="Class"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* getting all unique std values from data */}
                { reportData &&
                  [...new Set(reportData["allSchools"].map(item => item.std))].sort().map((item, i) => {
                  return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            {tabValue === 1 && 
              <FormControl sx={{ m: 1, minWidth: 150}} size="small">
                <InputLabel id="regionFilterLabel">Region</InputLabel>
                <Select
                  labelId="regionFilterLabel"
                  id="regionFilter"
                  value={schoolRegion}
                  onChange={handleSchoolRegionChange}
                  autoWidth
                  label="Region"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {/* getting all unique region values from data */}
                  { reportData && 
                    [...new Set(filteredReportData["allSchools"].map((item) => item.region))].map((item, i) => {
                      return <MenuItem key={i} value={item}>{item}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            }
          </div>
          <div className="currentFilters">
            <Typography variant="body1" gutterBottom>
              Current Filters: 
            </Typography>
            { superAdminInfo.allTests && 
              <Chip 
                variant="outlined" 
                color="info"  
                // onDelete={handleLangChipDelete} 
                icon={<EditNoteIcon />}
                label={superAdminInfo.allTests.filter(item=> item.testId === testId)[0].testName}
                sx={{maxWidth: 150}} 
              />
            }
            <Chip 
              variant="outlined" 
              color="info"  
              // onDelete={handleLangChipDelete} 
              icon={<TranslateIcon />}
              label={lang}
              // sx={{mr: 1}} 
            />
            {std && 
              <Chip 
                variant="outlined" 
                color="info"  
                // onDelete={handleStdChipDelete} 
                icon={<PinIcon />}
                label={std}
              />
            }
            {(tabValue === 1 && schoolRegion) && 
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleSchoolRegionChipDelete} 
                icon={<MapIcon />}
                label={schoolRegion}
                sx={{mr: 1}} 
              />
            }
          </div>
        </div>
        <div className="charts">
          {(reportData && tabValue === 0) &&
            <BarChart
              chartData = {lang === "English" ? 
                [...new Set(filteredReportData["allSchools"].map((item) => item.region))]
                  .map(region => filteredReportData["allSchools"].filter(item=> item.region === region)
                  .reduce((acc, item) => {
                    return [
                      acc[0] + item.enWcpmCat1,
                      acc[1] + item.enWcpmCat2,
                      acc[2] + item.enWcpmCat3,
                      acc[3] + item.enWcpmCat4,
                      acc[4] + item.enWcpmCat5,
                      acc[5] + item.enTotal,
                    ]
                  }, [0, 0, 0, 0, 0, 0]))
                          :
                  [...new Set(filteredReportData["allSchools"].map((item) => item.region))]
                  .map(region => filteredReportData["allSchools"].filter(item=> item.region === region)
                  .reduce((acc, item) => {
                    return [
                      acc[0] + item.hiWcpmCat1,
                      acc[1] + item.hiWcpmCat2,
                      acc[2] + item.hiWcpmCat3,
                      acc[3] + item.hiWcpmCat4,
                      acc[4] + item.hiWcpmCat5,
                      acc[5] + item.hiTotal,
                    ]
                  }, [0, 0, 0, 0, 0, 0]))
              }
              labelData = {[...new Set(filteredReportData["allSchools"].map((item) => item.region))]}
              catLabelData={lang === "English" ? 
                  [filteredReportData["thresholds"].enT1, filteredReportData["thresholds"].enT2, filteredReportData["thresholds"].enT3, filteredReportData["thresholds"].enT4]
                :
                  [filteredReportData["thresholds"].hiT1, filteredReportData["thresholds"].hiT2, filteredReportData["thresholds"].hiT3, filteredReportData["thresholds"].hiT4]
              }
              title = {"WCPM - Region Wise - " + lang}
              xLabel = "Regions"
              yLabel = "School %"
            />
            
          }
          {(reportData && tabValue === 1 && schoolRegion !=="") ? 
            <BarChart 
              chartData = {lang === "English" ? 
                            filteredReportData["allSchools"].filter(item => item.region === schoolRegion)
                              .map(item => [item.enWcpmCat1, item.enWcpmCat2, item.enWcpmCat3, item.enWcpmCat4, item.enWcpmCat5, item.enTotal])
                            :
                            filteredReportData["allSchools"].filter(item => item.region === schoolRegion)
                              .map(item => [item.hiWcpmCat1, item.hiWcpmCat2, item.hiWcpmCat3, item.hiWcpmCat4, item.hiWcpmCat5, item.hiTotal])
                          }
              labelData = {filteredReportData["allSchools"].filter(item => item.region === schoolRegion).map(data => data.schoolName)}
              catLabelData={lang === "English" ? 
                [filteredReportData["thresholds"].enT1, filteredReportData["thresholds"].enT2, filteredReportData["thresholds"].enT3, filteredReportData["thresholds"].enT4]
              :
                [filteredReportData["thresholds"].hiT1, filteredReportData["thresholds"].hiT2, filteredReportData["thresholds"].hiT3, filteredReportData["thresholds"].hiT4]
              }
              title = {"WCPM - School Wise - " + lang}
              xLabel = {"Schools - " + schoolRegion}
              yLabel = "Student %"
              chartName = {schoolRegion}
            /> 
          : (reportData && tabValue === 1) &&
            <h3>Select a Region First</h3>
          }
          {!reportData && <CircularProgress color="info"/> }
        </div>
      </div>
    </div>
  )
}

export default Report