import React, { useEffect, useState } from 'react'
import { Button, Chip, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import axios from "axios"
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import FilterListIcon from '@mui/icons-material/FilterList';
import TranslateIcon from '@mui/icons-material/Translate';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AbcIcon from '@mui/icons-material/Abc';
import PinIcon from '@mui/icons-material/Pin';
import EditNoteIcon from '@mui/icons-material/EditNote';

import Table from "../../TableComponent";
import BarChart from '../../BarChart';

import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

const Report = ({ facInfo }) => {
  const [reportData, setReportData] = useState("")
  const [testId, setTestId] = useState(facInfo.allTests ? facInfo.allTests[0].testId : null)
  
  useEffect(() => {
    if(facInfo.allTests){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
  
        const getReportData = async(facId, adminId, testId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/report?adminId="+adminId+"&testId="+testId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Report Data: ",response.data["report"])
            // adding full name by combining firstName and lastName
            const reportData = {...response.data["report"],
              "students": response.data["report"]["students"].map(stud => ({
              ...stud, 
              "fullName": stud["firstName"] + " " + stud["lastName"],
              "classSection": stud["std"] + "-" + stud["divn"],
              "enWcpm": stud["enWcpm"] === -3 ? "Not Tested" : stud["enWcpm"] === -2 ? "Audio Missing" : stud["enWcpm"] === -1 ? "Invalid Attempt" : stud["enWcpm"],
              "hiWcpm": stud["hiWcpm"] === -3 ? "Not Tested" : stud["hiWcpm"] === -2 ? "Audio Missing" : stud["hiWcpm"] === -1 ? "Invalid Attempt" : stud["hiWcpm"], 
              "enPhrasingScore": stud["enPhrasingScore"] === "-3.0" ? "Not Tested" : stud["enPhrasingScore"] === "-2.0"? "Audio Missing" : stud["enPhrasingScore"] === "-1.0" ? "Invalid Attempt" : stud["enPhrasingScore"]
            }))}
            // console.log(reportData)
            setReportData(reportData)
            setStd(reportData.class.map(item => item.std).sort()[0] || "")
            setDivn(reportData.class.map(item => item.divn).sort()[0] || "")
          }
          catch (error) {
              console.log(error)
          }
        }
  
        const facId = facInfo.facilitator[0].facId;
        const adminId = facInfo.facilitator[0].adminId;
        getReportData(facId, adminId, testId)
      })
      .catch((err) => console.log(err));
    }
    else{
      alert("No Test has been completed yet!\nRedirecting to Dashboard")
      window.location.replace("/");
    }
    
  }, [facInfo, testId]);

  const columns = [
    {
      key: "fullName",
      headerName: "Student Name (Only Initials)",
      width: 60
    },
    {
      key: "studId",
      headerName: "Student PEN",
      width: 80
    },
    // {
    //   key: "std",
    //   headerName: "Class",
    //   width: 30
    // },
    // { 
    //   key: "divn", 
    //   headerName: "Section", 
    //   width: 30 
    // },
    { 
      key: "classSection", 
      headerName: "Class", 
      width: 30 
    },
    { 
      key: "rollNo", 
      headerName: "Roll No", 
      width: 30 
    },
    { 
      key: "gender", 
      headerName: "Gender", 
      width: 30 
    },
    { 
      key: "enWcpm", 
      headerName: "WCPM (English)", 
      width: 80,
      tooltip: true
    },
    { 
      key: "hiWcpm", 
      headerName: "WCPM (Hindi)", 
      width: 80,
      tooltip: true 
    },
    {
      key: "reportUrl",
      headerName: "Report",
      width: 60,
      dialogue: "report",
      testId: testId
    }
    // { 
    //   key: "enPhrasingScore", 
    //   headerName: "Phrasing (English)", 
    //   width: 80,
    //   tooltip: "Either student did not attempt atleast half of the story or all paragraphs in the story were not attempted" 
    // }
  ];

  const headers = [
    { label: "Class", key: "std" },
    { label: "Section", key: "divn" },
    { label: "Student Name (Only Initials)", key: "fullName" },
    { label: "Gender", key: "gender" },
    { label: "Student PEN (Maximum 12 digit)", key: "studId"},
    { label: "Roll No", key: "rollNo" },
    { label: "WCPM (English)", key: "enWcpm" },
    { label: "WCPM (Hindi)", key: "hiWcpm" },
    // { label: "Phrasing (English)", key: "enPhrasingScore" }
  ];

  const orderBy = ["rollNo"];

  const [tabValue, setTabValue] = useState(0);
  const [lang, setLang] = useState("English");
  const [std, setStd] = useState("");
  const [divn, setDivn] = useState("");
  const [wordCloudData, setWordCloudData] = useState("")
  const [words, setWords] = useState("")
  const [wordCloudError, setWordCloudError] = useState("")

  const handleTabChange = (event, newValue) => {
    if(wordCloudData === "" && newValue===2){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;

        const getWordCloudData = async(facId, testId, classId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/word-cloud?testId="+testId+"&classId="+classId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log(response.data)
            setWordCloudData(response.data.wordCloud)
            setWords(response.data.wordCloud.EN)
          }
          catch (error) {
            if(error.response.status === 404) setWordCloudError("Word Cloud does not exist for your class!")
            else console.log(error)
          }
        }
        const facId = facInfo.facilitator[0].facId;
        const classId = facInfo.facilitator[0].schools[0].classes.filter(item => item.std === std && item.divn === divn)[0].classId
        getWordCloudData(facId, testId, classId)
      })
      .catch((err) => console.log(err));
    }

    setTabValue(newValue);
  };

  const handleTestIdChange = (e) => {
    setStd("");
    setDivn("");
    setReportData("");
    setTestId(e.target.value);
  };

  const handleStdChange = (e) => {
    setStd(e.target.value);
  };

  const handleDivnChange = (e) => {
    setDivn(e.target.value);
  };

  const handleLangChange = (e) => {
    setLang(e.target.value);
    if(wordCloudData){
      e.target.value === "English" ? setWords(wordCloudData.EN) : setWords(wordCloudData.HI)
    }
  };

  var filteredReportData
  if(std==="" && divn===""){
    filteredReportData = reportData
    // filteredSchoolFacInfo
  }
  // else if(std!=="" && divn===""){
  //   filteredReportData = {
  //     "allSchools": {...reportData.allSchools.filter(item => item.std === std)},
  //     "region": {...reportData.region.filter(item => item.std === std)},
  //     "class": {...reportData.class.filter(item => item.std === std)},
  //     "students": {...reportData.students.filter(item => item.std === std)}
  //   }
  // }
  // else if(std==="" && divn!==""){
  //   filteredReportData = [...reportData.class.filter(item => item.divn === divn)]
  // }
  else{
    filteredReportData = {
      "allSchools": reportData.allSchools.filter(item => item.std === std)[0],
      "region": reportData.region.filter(item => item.std === std)[0],
      "class": reportData.class.filter(item => item.std === std && item.divn === divn)[0],
      "students": reportData.students.filter(item => item.std === std && item.divn === divn),
      "thresholds": reportData["thresholds"].filter(item => item.std === std)[0]
    }
  }
  // console.log(filteredReportData)

  const colors = ['#143059', '#2F6B9A', '#82a6c2']
  var fontScale = ""
  if(wordCloudData) {
  fontScale = scaleLog({
    domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
    range: [10, 80],
  });
  }

  return (
    <div className='report facilitatorReport innerContent'>
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Results</h2>
      <div className="chartArea">
        <div className="topTabs">
          <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px"}}>
            <Tab label="Overall" sx={{fontSize: "inherit", minHeight: "30px"}}/>
            <Tab label="Student Wise" sx={{fontSize: "inherit", minHeight: "30px"}}/>
            <Tab label="Word Cloud" sx={{fontSize: "inherit", minHeight: "30px"}}/>
          </Tabs>
          <h4 style={{color: "#dc3545"}}>
            Results generated based on recordings received till {facInfo.allTests && 
              new Date(facInfo.allTests.filter(item=> item.testId === testId)[0].uploadEndDate).toLocaleDateString("en-GB", { dateStyle: "long" })}
          </h4>
        </div>
        <div className="filters">
          <div className="forms">
            <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
              <FilterListIcon fontSize="small"/>
              <span style={{height: "100%"}}>Filters:</span> 
            </h4>
            <FormControl sx={{ m: 1, minWidth: 150}} size="small">
              <InputLabel id="testNameFilterLabel">Test Name</InputLabel>
              <Select
                labelId="testIdFilterLabel"
                id="testIdFilter"
                value={testId ? testId : ""}
                onChange={handleTestIdChange}
                autoWidth
                label="Test Name"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* getting all unique std values from data */}
                {facInfo.allTests &&
                  facInfo.allTests.map(item => item.testName).map((item, i) => {
                  return <MenuItem key={i} value={facInfo.allTests[i].testId}>{item}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            { (tabValue === 0 || tabValue === 2) &&
              <FormControl sx={{ m: 1, minWidth: 120}} size="small">
                <InputLabel id="langFilterLabel">Language</InputLabel>
                <Select
                  labelId="langFilterLabel"
                  id="langFilter"
                  value={lang}
                  onChange={handleLangChange}
                  autoWidth
                  label="Language"
                >
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Hindi">Hindi</MenuItem>
                </Select>
              </FormControl>
            }
            <FormControl sx={{ m: 1, minWidth: 85}} size="small">
              <InputLabel id="stdFilterLabel">Class</InputLabel>
              <Select
                labelId="stdFilterLabel"
                id="stdFilter"
                value={std}
                onChange={handleStdChange}
                autoWidth
                label="Class"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* getting all unique std values from data */}
                {reportData &&
                  [...new Set(reportData.class.map(item => item.std))].sort().map((item, i) => {
                  return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
              <InputLabel id="divnFilterLabel">Section</InputLabel>
              <Select
                labelId="divnFilterLabel"
                id="divnFilter"
                value={divn}
                onChange={handleDivnChange}
                autoWidth
                label="Section"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* getting all unique divn values from data */}
                {reportData &&
                  [...new Set(reportData.class.filter(item => item.std === std).map(item => item.divn))].sort().map((item, i) => {
                  return <MenuItem key={i} value={item}>{item}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
          <div className="currentFilters">
            <Typography variant="body1" gutterBottom>
              Current Filters: 
            </Typography>
            { facInfo.allTests && 
              <Chip 
                variant="outlined" 
                color="info"  
                // onDelete={handleLangChipDelete} 
                icon={<EditNoteIcon />}
                label={facInfo.allTests.filter(item=> item.testId === testId)[0].testName}
                sx={{maxWidth: 150}} 
              />
            }
            { (tabValue === 0 || tabValue === 2) &&
              <Chip 
                variant="outlined" 
                color="info"  
                // onDelete={handleLangChipDelete} 
                icon={<TranslateIcon />}
                label={lang}
                // sx={{mr: 1}} 
              />
            }
            {std && 
              <Chip 
                variant="outlined" 
                color="info"  
                // onDelete={handleStdChipDelete} 
                icon={<PinIcon />}
                label={std}
              />
            }
            {divn &&
              <Chip 
                variant="outlined" 
                color="info"  
                // onDelete={handleDivnChipDelete} 
                icon={<AbcIcon />}
                label={divn} 
                sx={{mr: 1}} 
              />
            }
          </div>
        </div>
        {( reportData && tabValue === 0 ) &&
          <div className="charts">
            <BarChart
              chartData = {lang === "English" ? 
                [
                  [filteredReportData.allSchools.enWcpmCat1, filteredReportData.allSchools.enWcpmCat2, filteredReportData.allSchools.enWcpmCat3, filteredReportData.allSchools.enWcpmCat4, filteredReportData.allSchools.enWcpmCat5, filteredReportData.allSchools.enTotal],
                  [filteredReportData.region.enWcpmCat1, filteredReportData.region.enWcpmCat2, filteredReportData.region.enWcpmCat3, filteredReportData.region.enWcpmCat4, filteredReportData.region.enWcpmCat5, filteredReportData.region.enTotal], 
                  [filteredReportData.class.enWcpmCat1, filteredReportData.class.enWcpmCat2, filteredReportData.class.enWcpmCat3, filteredReportData.class.enWcpmCat4, filteredReportData.class.enWcpmCat5, filteredReportData.class.enTotal]
                ]
                :
                [
                  [filteredReportData.allSchools.hiWcpmCat1, filteredReportData.allSchools.hiWcpmCat2, filteredReportData.allSchools.hiWcpmCat3, filteredReportData.allSchools.hiWcpmCat4, filteredReportData.allSchools.hiWcpmCat5, filteredReportData.allSchools.hiTotal],
                  [filteredReportData.region.hiWcpmCat1, filteredReportData.region.hiWcpmCat2, filteredReportData.region.hiWcpmCat3, filteredReportData.region.hiWcpmCat4, filteredReportData.region.hiWcpmCat5, filteredReportData.region.hiTotal], 
                  [filteredReportData.class.hiWcpmCat1, filteredReportData.class.hiWcpmCat2, filteredReportData.class.hiWcpmCat3, filteredReportData.class.hiWcpmCat4, filteredReportData.class.hiWcpmCat5, filteredReportData.class.hiTotal]
                ]
              }
              catLabelData={lang === "English" ? 
                [filteredReportData["thresholds"].enT1, filteredReportData["thresholds"].enT2, filteredReportData["thresholds"].enT3, filteredReportData["thresholds"].enT4]
              :
                [filteredReportData["thresholds"].hiT1, filteredReportData["thresholds"].hiT2, filteredReportData["thresholds"].hiT3, filteredReportData["thresholds"].hiT4]
              }
              labelData = {["All Schools", "Same Region Schools", "Your Class"]}
              title = {"WCPM - All Schools vs Same Region Schools vs Your Class - " + lang}
              xLabel = {"Grade " + std}
              yLabel = "Student %"
            />
          </div>
        }
        {( reportData && tabValue === 1) &&
          <div className="studentsContent">
            <div className="tabs" style={{paddingTop: "0.5rem"}}>
              <Button variant="contained" color="info" startIcon={<FileDownloadIcon />} sx={{ placeSelf: "start" }}>
                <CSVLink data={filteredReportData.students} headers={headers} filename={"StudentsResultData.csv"}>
                  Download as CSV
                </CSVLink>
              </Button>
            </div>
            <Divider />
            <div className="table">
              <Table
                columns={columns}
                tableData={filteredReportData.students}
                orderBy={orderBy}
                // tableHeight="47vh" 
              />
            </div>
          </div>
        }
        {(!reportData || (!wordCloudData && !wordCloudError && tabValue === 2))&& <div className="charts"><CircularProgress color="info"/></div> }
        {((!wordCloudData && tabValue === 2) && wordCloudError) && <div className="charts"><h3>{wordCloudError}</h3></div> }
        {(wordCloudData && tabValue === 2) && 
          <div className="wordCloud">
            {/* <h3>Words Read Incorrectly</h3>
            <WordChart data={wordsReadIncorrectly}/>
            <h3>Words Omitted</h3>
            <WordChart data={wordsOmitted}/> */}
            {/* <h3>Words Read Incorrectly</h3> */}
            <Wordcloud
              words={words}
              width={800}
              height={480}
              fontSize={fontScale && ((w) =>  fontScale(w.value))}
              font={'Impact'}
              padding={4}
              // spiral={"rectangular"}
              rotate={0}
              random={() => 0.5}
            >
              {(cloudWords) =>
                cloudWords.map((w, i) => (
                  <Tooltip title={w.text + ": " + w.value} key={w.text} arrow>
                    <text
                      key={w.text}
                      fill={colors[i % colors.length]}
                      textAnchor={'middle'}
                      transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                      fontSize={w.size}
                      fontFamily={w.font}
                    >
                      {w.text}
                    </text>
                  </Tooltip>
                ))
              }
            </Wordcloud>
          </div>
        }
      </div>
    </div>
  )
}

export default Report