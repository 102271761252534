import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Routes, Route, Navigate } from "react-router-dom";
import { AlertTitle, CircularProgress, Divider, List, ListItem } from "@mui/material";
import MuiAlert from '@mui/material/Alert';

import Dashboard from "./Dashboard";
import School from "./School";
import Facilitator from "./Facilitator";
// import ExcelUpload from "./ExcelUpload";
import Report from "./Report";
import Students from "./Students"
import StudentExcelUpload from "./StudentExcelUpload";

const AdminContent = () => {
  const [adminInfo, setAdminInfo] = useState("")
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState([])

  useEffect(() => {
    Auth.currentSession()
    .then((data) => {
      const jwtToken = data.idToken.jwtToken;
      const cognitoUsername = data.idToken.payload["cognito:username"];

      const getAdminInfo = async() => {
        try {
          const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+cognitoUsername, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': jwtToken
            }
          });
          // console.log(response.data)
          setAdminInfo(response.data)
        }
        catch (error) {
          setError(true)
          setErrorMsg([error.code, error.message])
          console.log(error)
        }
      }

      getAdminInfo()
    })
    .catch((err) => console.log(err));
    
  }, []);

  return (
    <div className="content">
      {adminInfo 
        ? 
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/school" element={<School adminInfo={adminInfo}/>} />
          <Route path="/facilitator" element={<Facilitator adminInfo={adminInfo}/>} />
          {/* <Route path="/excel-upload" element={<ExcelUpload adminInfo={adminInfo}/>} /> */}
          <Route path="/students" element={<Students adminInfo={adminInfo}/>} />
          <Route path="/student-excel-upload" element={<StudentExcelUpload adminInfo={adminInfo}/>} />
          <Route path="/results" element={<Report adminInfo={adminInfo}/>} />
          <Route path="*" element={<Navigate to="/" replace/>} />
        </Routes>
        :
        <div className="blankScreen">
          {error 
            ?
            <MuiAlert severity="warning" className="blankScreenError" sx={{borderRadius: "10px"}}>
              <AlertTitle><h3>Connection Issue:</h3></AlertTitle>
                <Divider/>
                <List>
                  <ListItem disableGutters><h3>Please check your Internet Connectivity & try refreshing the page.</h3></ListItem>
                  <ListItem disableGutters><p>If the issue persists please contact IITB Team using the following <span></span>
                    <a href="https://forms.gle/fnU48UpGWWs5zgoT6" target="_blank" rel="noopener noreferrer" style={{color: "rgb(25, 118, 210)", textDecoration: "underline", textUnderlinePosition: "under"}}> 
                      form.
                    </a></p>
                  </ListItem>
                  {errorMsg && <ListItem disableGutters><p>Response Code: {errorMsg[0]}, Response Message: {errorMsg[1]}</p></ListItem>}
                </List>
            </MuiAlert>
            :
            <CircularProgress color="info"/>
          }
        </div>
      }
    </div>
  )
};

export default AdminContent;
